import React, { Component } from 'react'
import Link from '../utils/link'

class Header extends Component {

  state = {
    offCanvas: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    //console.log(this.props.transitionStatus)

    return (
      <>
        <header className={`header ${ offCanvas ? 'header--active' : '' } header--${this.props.transitionStatus}`}>
          <div className='header__inner'>
            <Link to='/' title='Visual Thing' className='header__logo' {...props}>
              Visual Thing
            </Link>
            <button onClick={this._toggleOffCanvas} className='header__hamburger'>
              Menu
            </button>
            <button className='header__hamburger header__hamburger--job' onClick={() => window.history.go(-1)}>
              Back
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <div className='off-canvas__header'>
              <Link to='/' title='Visual Thing' className='off-canvas__logo' {...props}>
                Visual Thing
              </Link>
              <button onClick={this._toggleOffCanvas} className='off-canvas__hamburger'>
                Close
              </button>
            </div>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/about-us/' {...props}>About</Link></li>
                <li><Link to='/expertise/' {...props}>Expertise</Link></li>
                <li><Link to='/contact-us/' {...props}>Contact</Link></li>
                <li><Link to='https://vtdam.visualthing.com.au/'>Log In</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
